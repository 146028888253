.TamweelOptions-module__container {
  max-width: 762px;
  margin: 0 auto;
  margin-top: 108px;
}

.TamweelOptions-module__imgTitle > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 295px;
  z-index: -1;
}

.TamweelOptions-module__imgTitle {
  /* background-color: blue; */
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 295px;
  box-sizing: border-box;
  padding-top: 24px;
  margin-bottom: -147px;
  border-radius: 12px;
}

.TamweelOptions-module__imgTitle > strong {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TamweelOptions-module__imgTitle > strong > span {
  color: #ffffff;
}

.TamweelOptions-module__imgTitle > strong > span:first-child {
  font-size: 22px;
  line-height: 33px;
}

.TamweelOptions-module__imgTitle > strong > span:last-child {
  font-size: 53px;
  line-height: 67px;
}

body[dir="rtl"] .TamweelOptions-module__imgTitle > strong > span:last-child {
  font-size: 53px;
  line-height: 67px;
  margin-top: -15px;
}

.TamweelOptions-module__tBox {
  max-width: 570px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.TamweelOptions-module__tBox > div {
  padding: 16px;
  box-sizing: border-box;
  background: rgb(241, 246, 252);
  background: linear-gradient(
    0deg,
    rgba(241, 246, 252, 1) 0%,
    rgba(249, 251, 254, 1) 100%
  );
  border-radius: 8px;
  width: calc(50% - 22px / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.TamweelOptions-module__tBox > div > img,
.TamweelOptions-module__tBox > div > span > img {
  width: 100%;
  height: unset;
}

.TamweelOptions-module__tBox > div > p {
  color: #154f9c;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}
.TamweelOptions-module__container > a {
  width: 178px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 39px;
}

@media screen and (max-width: 1024px) {
  .TamweelOptions-module__container {
    margin-top: 0;
    max-width: 90%;
    margin-top: 48px;
  }
  .TamweelOptions-module__imgTitle {
    background-position: 50%;
  }
  .TamweelOptions-module__tBox > div {
    width: calc(50% - 12px / 2);
  }
  .TamweelOptions-module__tBox {
    justify-content: space-around;
  }
  .TamweelOptions-module__tBox > div > p {
    font-size: 12px;
    line-height: 20px;
  }
  .TamweelOptions-module__container > a {
    font-size: 14px;
    line-height: 24px;
  }
}
